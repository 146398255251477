import { graphql, navigate } from 'gatsby';
import React from 'react';

import { Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import GreenFanGoLinkButton from '../components/common/button/GreenFanGoLinkButton';
import StandardSEO from '../components/common/seo/StandardSEO';
import styles from '../components/common/styling/styles';
import { MainText } from '../components/common/texts';
import ClientsMap from '../components/PageComps/ClientsPageComps/ClientPageMapComps/ClientsMap';
import { Form } from '../components/PageComps/CommonComps';
import BlogsPreview from '../components/PageComps/CommonComps/blogsPreview/BlogsPreview';
import BasicTitleSubTitle from '../components/PageComps/CommonComps/heroSections/heroSectionComps/BasicTitleSubTitle';
import VideoBackground from '../components/PageComps/CommonComps/heroSections/heroSectionComps/VideoBackground';
import ScrollingImageBanner from '../components/PageComps/CommonComps/TrustedBrandIcons/ScrollingImageBanner';
import {
  EventsOfferingsCTA,
  HowWeBuild,
  SoftwareFeatures,
  TestimonialCarousel,
} from '../components/PageComps/HomePageComps';
import getSoftwareFeaturesStyles from '../utils/getSoftwareFeaturesStyles';
import { getFormData } from '../utils/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleBannerButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1200,
      margin: 'auto',
      rowGap: 30,
    },
    fullWidthContainer: {
      paddingTop: 35,
      width: '100%',
      backgroundColor: 'white',
    },
  })
);

const HomePage = ({ data }) => {
  const classes = useStyles();
  const { sanityHomePage } = data;
  const { homepageTopSection, pageHead } = sanityHomePage;
  const { softwareFeatures, subTitle, title, backgroundVideoPlaceholder } =
    homepageTopSection;
  const { videoID, title: pageTitle, subTitle: pageSubTitle } = pageHead;
  const mobileScreen = useMediaQuery(`(max-width: 435px)`);

  const { sanityCommonContent } = data;
  const { clientList } = sanityCommonContent;

  const addTextMapMargin = useMediaQuery('(min-width: 600px)');
  const formData = getFormData({ data });

  const containerHeight = mobileScreen ? '80vh' : '80vh';

  return (
    <>
      <VideoBackground
        imgData={backgroundVideoPlaceholder}
        videoID={videoID}
        maxHeight={containerHeight}
        videoMinHeight={containerHeight}
        mobileScreen={mobileScreen}
        shadow={0.7}
        textContent={
          <BasicTitleSubTitle
            containerHeight={containerHeight}
            font="Domine"
            title={pageTitle}
            subTitle={pageSubTitle}
            subTitlePlainText={true}
            showRequestADemo={true}
            hideSubTitleOnMobile={true}
            largeSubTitleSize={true}
            mobileScreen={mobileScreen}
            titleRowSpacing={30}
            titlePaddingBottom={0}
          />
        }
      />
      <div style={{ paddingTop: 35, width: '100%', backgroundColor: 'white' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 1200,
            margin: 'auto',
            rowGap: 30,
          }}
        >
          <MainText
            textVariant="h4"
            fontColor={styles.darkBlue}
            paddingX={styles.allThree}
            value="FanGo’s goal is to eliminate event admin, streamline the booking process and improve the guest experience whilst driving sales 24/7. FanGo is trusted by hundreds of venues across the UK"
          />

          <ScrollingImageBanner />
          <GreenFanGoLinkButton
            onClick={() => navigate('/clients')}
            buttonText="Reviews & Testimonials"
          />
        </div>
      </div>

      <SoftwareFeatures
        data={data.sanityHomePage.softwareFeature}
        featureStyles={getSoftwareFeaturesStyles({ page: 'homepage' })}
      />

      <EventsOfferingsCTA data={data.sanityHomePage.eventOffering} />

      <div
        style={{ backgroundColor: 'white', paddingBottom: 25, paddingTop: 25 }}
      >
        <Typography
          variant="h2"
          align="center"
          sx={{ mb: 4, color: '#141855' }}
        >
          Our Clients
        </Typography>
        <Grid container>
          <Grid item xs={0} sm={1} />
          <Grid
            item
            xs={12}
            sm={5}
            sx={{ mb: 2, mt: addTextMapMargin ? '4%' : 0 }}
          >
            <Typography
              sx={{ mb: 3, color: '#141855' }}
              variant="h4"
              align="center"
            >
              We are proud to work with a diverse array of clients across the
              UK. Our commitment to excellence has earned us the trust and
              partnership of over 100 clients
            </Typography>
            <Typography sx={{ mb: 3 }} align="center">
              Discover where our clients are located with our interactive map
            </Typography>
            <Typography align="center">
              Click on the markers to learn more about our clients and the
              systems we’ve developed together
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <ClientsMap clientsList={clientList} />
          </Grid>
          <Grid item xs={0} sm={1} />
        </Grid>
      </div>
      {/* <FeatureBrandIcons data={data.sanityHomePage.asFeatured} /> */}
      <HowWeBuild data={data.sanityHomePage.howWeBuild} />

      <TestimonialCarousel
        data={data.sanityCommonContent.testimonialCarousel}
      />

      <BlogsPreview
        data={{
          ...data.sanityHomePage.blogsPreview,
          blogs: data.allSanityPost.nodes,
        }}
      />
      <Form data={formData} />
    </>
  );
};

export default HomePage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityHomePage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityHomePage(language: { eq: $language }) {
      seo {
        title
        description
        keywords
        name
        type
        previewImage {
          asset {
            url
          }
        }
      }

      pageHead {
        title
        subTitle
        buttonName
        playButtonActionText
        contactForm {
          title
          subTitle
          demoFormFields {
            key
            label
            required
            multiline
          }
          buttonName
        }
        referralOptions {
          referralOptionsList {
            optionName
            showOption
          }
        }
        videoID
      }
      homepageTopSection {
        title
        subTitle
        backgroundPlaceholder {
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 1000, formats: [WEBP])
          }
          altText
        }
        backgroundVideoPlaceholder {
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 1000, formats: [WEBP])
          }
          altText
        }
        productImages {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 600, formats: [WEBP])
          }
        }
        softwareFeatures
      }
      softwareFeature {
        title
        _rawBody
        productFeatures {
          internalLink
          cardImage {
            asset {
              gatsbyImageData(placeholder: BLURRED, width: 400, formats: [WEBP])
            }
            altText
          }
          Icon
          _key
          feature
          description
        }
        image {
          asset {
            gatsbyImageData
          }
          altText
        }
      }
      eventOffering {
        title
        body: _rawBody
        howItWorks {
          children {
            _key
            text
          }
        }
        buttonName
      }
      howWeBuild {
        title
        howWeBuild {
          Icon
          _key
          body: _rawBody
          header
        }
        cta
      }
      blogsPreview {
        title
        buttonName
      }
      asFeatured {
        title
        images {
          asset {
            gatsbyImageData(placeholder: BLURRED, height: 50, formats: [WEBP])
          }
          altText
        }
      }
    }
    sanityCommonContent(language: { eq: $language }) {
      trustedBy {
        title
      }
      clientList {
        clientCoordinates {
          latitude
          longitude
        }
        clientEventsPageURL
        clientName
        clientWebsiteURL
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 400, formats: [WEBP])
          }
          altText
        }
      }
      backgrounds {
        altText
        asset {
          gatsbyImageData(placeholder: BLURRED, height: 1000, formats: [WEBP])
        }
      }
      testimonialCarousel {
        title
        testimonies {
          name
          image {
            altText
            asset {
              gatsbyImageData(
                placeholder: BLURRED
                height: 300
                formats: [WEBP]
              )
              url
            }
          }
          jobTitle
          company
          testimony
          caseStudy
        }
      }
    }
    allSanityPost(
      sort: { publishedAt: DESC }
      filter: { language: { eq: $language } }
    ) {
      nodes {
        title
        slug {
          current
        }
        publishedAt
        author
        excerpt
        mainImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 600, formats: [WEBP])
          }
          altText
        }
        category
        paragraphs {
          title
          body: _rawBody
          image {
            asset {
              altText
              url
            }
          }
        }
      }
    }
  }
`;

// Under mainImage
// asset {
//   url
// }
{
  /* <PageHeadVideo
        backgroundVideo={christmasBackground}
        CentralContent={undefined}
        videoShading={0.2}
        scrollDownButton={false}
        leftGridSpacing={false}
        rightGridSpacing={false}
        videoHeight={0.8}
        placeholderImg={imageData}
        autoPlayVideo={false}
      /> */
}
{
  /* <FullImageBackgroundHeader
        imageData={imageData}
        showScrollDownButton={false}
        height={dimensions.height}
        content={
          <BasicTitleSubTitle
            shadowHeight={dimensions.height}
            font="Domine"
            title={pageTitle}
            subTitle={pageSubTitle}
            subTitlePlainText={true}
            maxTitleWidth="70%"
            paddingBottom="0px"
            shadow="0.5"
          />
          // <SplitContentTypewriter
          //   title={title}
          //   subTitle={subTitle}
          //   typeWriterText={formattedSoftwareFeatures}
          //   rightSideContent={<MobileAndGraph />}
          // />
        }
      /> */
}
